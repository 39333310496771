<script>
export default {
  beforeMount() {
  }
}
</script>

<template>
  <section class="container terms-container">
    <p>Your privacy is of paramount importance to us. In fact, we know that
      you care how information about you is used and shared, and we
      appreciate your trust that we will do so carefully and sensibly. We do
      not provide your personal information (such as your name, email,
      phone number, address, or credit card information) to other
      companies.
      </p>
    <p>Please read this Privacy Policy carefully for full details.</p>
    <p><strong>What information do we collect?</strong></p>
    <p>We collect information from you when you register on our site by
      completing the online form.</p>
    <p>When registering on our site, as appropriate, you may be asked to
      enter your: name, email address and phone number. You may,
      however, visit our site anonymously.</p>
    <p>No mobile information will be shared with third parties/affiliates for
      marketing/promotional purposes; all the above categories exclude
      text messaging originator opt-in data and consent; this information
      will not be shared with any third parties. Follow link for our full <a href="/sms">SMS Texting
      Policy</a>.</p>
    <p><strong>What do we use your information for?</strong></p>
    <p>Any of the information we collect from you may be used in one of
      the following ways:</p>
    <ul>
      <li>To personalize your experience</li>
    </ul>
    <blockquote>Your information helps us to better respond to your individual needs</blockquote>
    <ul>
      <li>To improve customer service</li>
    </ul>
    <blockquote>Your information helps us to more effectively respond to your
      customer service requests and support needs</blockquote>
    <ul>
      <li>To process transactions</li>
    </ul>
    <blockquote>Your information, whether public or private, will not be sold,
      exchanged, transferred, or given to any other company for any
      reason whatsoever, without your consent, other than for the express
      purpose of delivering the purchased product or service requested.</blockquote>
    <ul>
      <li>To send updates</li>
    </ul>
    <p>The email address and phone number you provide for order
      processing will be used to send you information and updates
      pertaining to your order/service.</p>
    <p><strong>Note</strong>: If at any time you would like to unsubscribe from receiving
      future emails and SMS messages, we include detailed unsubscribe
      instructions at the bottom of each email and at the end of each SMS
      text message. Follow link for full <a href="/sms">SMS Texting
        Policy</a>.</p>
    <p><strong>How do we protect your information?</strong></p>
    <p>We implement a variety of security measures to maintain the safety
      of your personal information when you enter, submit, or access your
      personal information. While no website can guarantee security, we
      have implemented appropriate administrative, technical, and
      physical security procedures to help protect the personal
      information you provide to us. For example, only authorized
      employees are permitted to access personal information, and they
      may only do so for permitted business functions. In addition, we use
      encryption when transmitting your sensitive personal information
      between your system and ours, and we employ firewalls and
      intrusion detection systems to help prevent unauthorized persons
      from gaining access to your information.</p>
    <p><strong>Do we use cookies?</strong></p>
    <p>Yes, cookies are small files that a site or its service provider transfers
      to your computer’s hard drive through your Web browser (if you
      allow) that enables the sites or service providers systems to
      recognize your browser and capture and remember certain
      information.</p>
    <p>We use cookies to understand and save your preferences for future
      visits and compile aggregate data about site traffic and site

      interaction so that we can offer better site experiences and tools in
      the future.</p>
    <p><strong>Do we disclose any information to outside parties?</strong></p>
    <p>
      We do not sell, trade, or otherwise transfer to outside parties your
      personally identifiable information. This does not include trusted
      third parties who assist us in operating our website, conducting our
      business, or servicing you, so long as those parties agree to keep this
      information confidential. We may also release your information
      when we believe release is appropriate to comply with the law,
      enforce our site policies, or protect ours or others’ rights, property, or
      safety.
    </p>
    <p><strong>California Online Privacy Protection Act Compliance</strong></p>
    <p>Because we value your privacy we have taken the necessary
      precautions to be in compliance with the California Online Privacy
      Protection Act. We therefore will not distribute your personal
      information to outside parties without your consent.</p>
    <p><strong>Children’s Online Privacy Protection Act Compliance</strong></p>
    <p>We are in compliance with the requirements of COPPA (Children’s
      Online Privacy Protection Act), we do not collect any information
      from anyone under 13 years of age. Our website, products and
      services are all directed to people who are at least 13 years old or
      older.</p>
    <p><strong>Visiting our Website from Outside the United States</strong></p>
    <p>If you are visiting our website from outside the United States, please
      be aware that your information may be transferred to, stored, and
      processed in the United States, where our servers are located and
      our central database is operated. The data protection and other laws
      of the United States and other countries might not be as
      comprehensive as those in your country, but rest assured that we
      take steps to ensure that your privacy is protected. By using our
      services, you understand that your information may be transferred to
      our facilities and those third parties with whom we share it as
      described in this Policy.</p>
    <p><strong></strong></p>
    <p></p>
    <p><strong></strong></p>
    <p></p>

    <p><strong>U.S.-Swiss Safe Harbor Framework</strong></p>
    <p>As described on <a href="https://www.ftc.gov/business-guidance/resources/federal-trade-commission-enforcement-us-eu-us-swiss-safe-harbor-frameworks" target="_blank">Safe Harbor Privacy Statement</a>, we comply with the
      US-EU Safe Harbor Framework and the US-Swiss Safe Harbor
      Framework as set forth by the US Department of Commerce
      regarding the collection, use and retention of personal information
      from European Union member countries and Switzerland.</p>
    <p><strong>Your Consent</strong></p>
    <p>By using our site, you consent to our Privacy Policy. Follow link to
      view our full <a href="/sms">SMS Texting
        Policy</a>.</p>
    <p><strong>Changes to Our Privacy Policy</strong></p>
    <p>If we decide to change our privacy policy, we will post those changes
      on this page, and/or update the Privacy Policy modification date
      below.</p>
    <p>This policy was last modified on 08/25/2023.</p>
    <p><strong>Contacting Us</strong></p>
    <p>For quick answers to your questions email:</p>
    <p><a href="mailto:info@thrivesource.com">info@thrivesource.com</a></p>
  </section>
</template>

<style scoped>
* {
  font-family: "Montserrat", sans-serif;
  font-size: 1.8rem;
  line-height: 2.4rem;
}

p {
  margin-top: 20px;
}

.terms-container {
  margin-top: 20px;
  margin-bottom: 50px;
}

blockquote {
  font-style: italic;
  padding: 0;

  &:before {
    content: "";
  }
}
</style>