<template>
  <header class="header" v-if="$route.name !== 'submit'">
    <div class="header-box">
      <router-link :to="{name: 'main'}">
        <img class="logo" alt="Thrive Logo" src="img/ThriveSource.svg"  />
      </router-link>
      <nav class="main-nav">
        <ul class="main-nav-list">
          <li><a class="main-nav-link" href="#solutions">Solutions</a></li>
          <li><a class="main-nav-link" href="#about">About</a></li>
          <li><a class="main-nav-link" href="#steps">How it works</a></li>
          <li><a class="main-nav-link nav-cta" href="#contact">Contact Us</a></li>
        </ul>
      </nav>

      <button class="btn-mobile-nav">
        <ion-icon class="icon-mobile-nav" name="menu-outline"></ion-icon>
        <ion-icon class="icon-mobile-nav" name="close-outline"></ion-icon>
      </button>
    </div>
  </header>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="scss">
@import "src/assets/css/style";
@import "src/assets/css/sections";
@import "src/assets/css/queries";
</style>
