import { createApp } from 'vue'
import App from './App.vue'
import MainPage from "@/components/MainPage.vue";
import PrivacyPage from "@/components/PrivacyPage.vue";
import SMSPolicy from "@/components/SMSPolicy.vue";
import SubmitPage from "@/components/SubmitPage.vue";
// import TermsPage from "@/components/TermsPage.vue";
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    { path: '/', component: MainPage, name: 'main' },
    { path: '/privacy', component: PrivacyPage, name: 'privacy' },
    { path: '/sms', component: SMSPolicy, name: 'sms' },
    { path: '/submit', component: SubmitPage, name: 'submit' },
    // { path: '/terms', component: TermsPage, name: 'terms' },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
    },
})

createApp(App).use(router).mount('#app')
